import React from 'react';
import WhyPoster from '../../images/whyposter.jpg';

const PosterDownload = data => (
    <section className="page-section" id="whyposter">
        <div className="container smallestwdt">
            <h2 className="bluetxt">Poster Download</h2>
            <h4
                dangerouslySetInnerHTML={{
                    __html: data.posterContent,
                }}
            />
            <h3 className="greentxt">Download The Why We Rock Poster</h3>
            <img
                className="whyposterimg"
                src={data.posterImage.localFile.childImageSharp.fluid.src}
                alt="poster"
            />
            <a
                href={data.posterImage.localFile.childImageSharp.fluid.src}
                target="_blank"
                rel="noopener noreferrer"
                className="wrtsbtn yellowbtn"
            >
                DOWNLOAD
            </a>
        </div>
    </section>
);

export default PosterDownload;
